export const columns = [
  {
    field: "no",
    label: "学号",
    align: "left",
    width: "auto,",
  },
  {
    field: "username",
    label: "姓名",
    align: "left",
    width: "auto,",
  },
  {
    field: "room",
    label: "班级",
    align: "left",
    width: "auto,",
  },
  {
    field: "gender",
    label: "性别",
    align: "left",
    width: "auto,",
  },
  {
    field: "birthday",
    label: "生日",
    align: "left",
    width: "auto,",
  },
  {
    field: "created_time",
    label: "创建时间",
    align: "left",
    width: "auto,",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "230",
  },
];

export const searchOptions = [
  {
    type: "input",
    field: "username",
    label: "姓名",
    placeholder: "按姓名查询",
  },
  {
    type: "input",
    field: "no",
    label: "学号",
    placeholder: "按学号查询",
  }
];
