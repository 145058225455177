<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-05-07 14:53:26
 * @LastEditors:
 * @LastEditTime: 2023-05-07 14:53:26
-->
<template>
  <Dialog
    :title="'【' + studentInfo.username + '】更新班级'"
    confirmText="保 存"
    width="420px"
    height="210px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <el-cascader
      v-loading="loading"
      clearable
      v-model="roomId"
      style="width: 100%"
      :options="options"
    ></el-cascader>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {
    options:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      studentInfo: {}, // 教师信息
      roomId: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 打开窗口
    async open(info) {
      this.visible = true;
      this.studentInfo = info;
      this.roomId = [parseInt(info.room["level"]), parseInt(info.room["name"])];
    },

    // 确定设置为班主任
    handleConfirm() {
      let params = {
        studentId: this.studentInfo.id,
        roomId: this.roomId[1],
      };
      this.$cloud.post("/student/set_room", params).then(() => {
        this.$message.success("更新班级成功！");
        this.visible = false;
        this.$emit("refresh");
      }).catch(err=>{
        this.$message.error(err)
      });
    },
    
  },
};
</script>
<style lang="scss" scoped></style>
