<!--
 * ======================================
 * 说明： 学生档案新增与编辑
 * 作者： Silence
 * 文件： edit.vue
 * 日期： 2023/5/10 9:09
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    :title="`${title}学生信息`"
    confirmText="保 存"
    width="600px"
    height="340px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <div v-loading="loading">
      <el-form
        :model="editForm"
        :rules="rules"
        ref="editForm"
        label-width="100px"
        label-position="right"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="学号：" prop="no">
              <el-input v-model.trim="editForm['no']" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="姓名：" prop="username">
              <el-input
                v-model.trim="editForm['username']"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="生日：" prop="birthday">
              <el-date-picker
                style="width: 100%"
                v-model="editForm['birthday']"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="性别：" prop="gender">
              <el-radio-group v-model="editForm['gender']">
                <el-radio-button label="MALE">男</el-radio-button>
                <el-radio-button label="FEMALE">女</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {},
  data() {
    return {
      title: "新增",
      mode: 1,
      visible: false,
      editForm: {
        gender: "MALE",
      },
      loading: false,
      rules: {
        no: [
          {
            required: true,
            trigger: "blur",
            message: "请输入学生学号！",
          },
        ],
        username: [
          {
            required: true,
            trigger: "blur",
            message: "请输入学生姓名！",
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    open(data) {
      this.editForm = {
        gender: "MALE",
      };
      this.mode = data["mode"];
      this.visible = true;
      if (parseInt(this.mode) === 1) {
        this.title = "新增";
      } else {
        this.editForm = data["editForm"];
        this.title = "更新";
      }
    },

    /**
     *  @actions:  确认与保存
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/10 9:09
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {
      this.$refs["editForm"].validate((vaild) => {
        if (vaild) {
          let message = "确定创建当前学生信息吗？",
            url = "/student/create";
          if (this.mode === 2) {
            message = "确定保存当前学生信息吗？";
            url = "/student/edit";
          }
          this.$confirm(message, "提示", {
            type: "warning",
          }).then(() => {
            this.loading = true;
            this.$cloud
              .post(url, this.editForm)
              .then(() => {
                this.loading = false;
                this.$emit("refresh");
                if (this.mode === 2) {
                  this.$message.success("更新成功！");
                  this.visible = false;
                } else {
                  this.$message.success("创建成功！");
                  this.open({ mode: 1 });
                }
              })
              .catch((err) => {
                this.$message.error(err)
                this.loading = false;
              });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
