<template>
  <div>
    <Main
      class="h-full"
      ref="main"
      :on-params="onParams"
      :columns="columns"
      :is-selection="false"
      api="/student/list"
      @onSelectionChange="handleOnSelectionChange"
    >
      <template #search>
        <Search
          v-model="searchForm"
          :options="searchOptions"
          @onSearch="onFind()"
        >
          <el-form-item label="班级:" prop="room">
            <el-cascader
              clearable
              v-model="room"
              placeholder="按班级查询"
              style="width: 100%"
              :options="options"
            ></el-cascader>
          </el-form-item>
        </Search>
      </template>
      <template #action>
        <el-button type="success" @click="handleCreateStedent">新增</el-button>
        <el-button type="primary" @click="handleBuildPassword"
          >生成初始密码</el-button
        >
      </template>
      <template #table-item-room="{ row }">
        <template v-if="row.room.level && row.room.name">
          {{ row.room.level
          }}{{
            row.room.name && row.room.name.length > 1
              ? row.room.name
              : "0" + row.room.name
          }}
          班
        </template>
        <template v-else>-</template>
      </template>
      <template #table-item-gender="{ row }">
        {{
          row["gender"] === "MALE"
            ? "男"
            : row["gender"] === "FEMALE"
            ? "女"
            : "-"
        }}
      </template>
      <template #table-item-action="{ row }">
        <el-button type="text" @click="handleResetPassword(row)"
          >密码重置</el-button
        >
        <el-button type="text" @click="handleClasss(row)">更新班级</el-button>
        <el-button type="text" @click="handleUpdateTeacher(row)"
          >修改</el-button
        >
        <el-button
          type="text"
          class="text-red-600"
          @click="handleDeleteTeacher(row)"
          >删除</el-button
        >
      </template>
    </Main>
    <Edit ref="edit" @refresh="onRefresh"></Edit>
    <UpdateClass
      ref="updateClass"
      :options="options"
      @refresh="onRefresh"
    ></UpdateClass>
  </div>
</template>
<script>
import Main from "../components/main/index.vue";
import Search from "../components/search/index.vue";
import { columns, searchOptions } from "./config";
import Edit from "./components/edit.vue";
import UpdateClass from "./components/updateClass.vue";
export default {
  name: "",
  components: { Main, Search, Edit, UpdateClass },
  props: {},
  data() {
    return { columns, searchOptions, searchForm: {}, room: [], options: [] };
  },
  created() {},
  async mounted() {
    await this.getClassOptions();
  },
  methods: {
    /**
     *  @actions:  设置查询参数
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/10 9:16
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onParams() {
      this.searchForm.roomId = this.room[this.room.length - 1];
      return this.searchForm;
    },

    // 获取班级待选项
    async getClassOptions() {
      this.loading = true;
      this.$cloud
        .get("/room/list", {
          pagelndex: 1,
          pageSize: 999,
        })
        .then(async (res) => {
          this.loading = false;
          if (res.list && typeof res.list == "object" && res.list.length) {
            let arrays = [];
            res.list.map((item) => {
              let findIndex = null;
              arrays.map((part, index) => {
                if (part.value === item.level) {
                  findIndex = index;
                }
              });
              let label =
                String(item.level) +
                (item.name.length > 1 ? String(item.name) : "0" + item.name) +
                " 班";
              if (findIndex || findIndex === 0) {
                arrays[findIndex].children.push({
                  value: item["id"],
                  label: label,
                });
              } else {
                arrays.push({
                  label: item.level + "年级",
                  value: item.level,
                  children: [
                    {
                      value: item["id"],
                      label: label,
                    },
                  ],
                });
              }
            });
            this.options = arrays;
            // 查询学生
            await this.onFind();
          }
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },

    /**
     *  @actions:  查询列表数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/10 9:16
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onFind() {
      console.log('this.$refs["main"]', this.$refs["main"]);
      await this.$refs["main"]?.onFind();
    },

    /**
     *  @actions:  刷新列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/10 9:16
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onRefresh() {
      this.$refs["main"].onQuery();
    },

    /**
     *  @actions:  创建学生
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/10 9:17
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCreateStedent() {
      this.$refs["edit"].open({ mode: 1 });
    },

    /**
     *  @actions:  生成初始密码
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/10 22:55
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleBuildPassword() {
      this.$confirm(
        "生成初始密码是对系统中全体学生生成初始化密码，初始密码为学号。是否继续操作?",
        "提示",
        {
          confirmButtonText: "继续",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$cloud.get("student/createpassword").then(() => {
            this.$message.success("初始密码生成成功！");
          });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  密码重置
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/1 22:45
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleResetPassword(row) {
      this.$confirm("确定重置当前学生的密码吗？", "提示", {
        type: "warning",
      }).then(() => {
        this.$cloud
          .post("/student/edit_password", {
            id: row.id,
            password: row.no,
          })
          .then(() => {
            this.$message.success("密码重置成功！");
          });
      });
    },

    /**
     *  @actions:  更新班级
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/12 0:07
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleClasss(row) {
      this.$refs["updateClass"].open(row);
    },

    // 修改学生信息
    handleUpdateTeacher(row) {
      this.$refs["edit"].open({ mode: 2, editForm: row });
    },

    // 删除学生信息
    handleDeleteTeacher(row) {
      this.$confirm("确定删除该学生信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$cloud.get("/student/delete", { id: row["id"] }).then((err) => {
            console.log('err:',err)
            this.$message.success("删除成功！");
            this.onRefresh();
          });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    // table  选中的数据
    handleOnSelectionChange() {},
  },
};
</script>
<style lang="scss" scoped></style>
